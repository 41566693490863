import request from '@/utils/request'

// 查询站点配置
export function getCustomizeSite(params) {
  return request({
    url: `/customize_site`,
    method: 'get',
    params: params
  })
}

