<template>
  <div>
    <a-row>
      <a-col span="24">
        <a-descriptions
          title="站点配置"
          :column="1"
          class="simiot-descriptions"
        >
          <a-descriptions-item label="是否显示LOGO">
            {{ isShowSiteLogo }}
          </a-descriptions-item>

          <a-descriptions-item label="自定义LOGO">
            <img
              :src="siteLogoUrl"
              style="height:64px;padding:5px;"
              alt=""
            >
          </a-descriptions-item>
        </a-descriptions>
      </a-col>
    </a-row>
  </div>
</template>

<script>
import { getCustomizeSite } from '@/api/customize_site'

export default {
  name: 'ShowCustomizeSite',
  data() {
    return {
      isShowSiteLogo: '',
      siteLogoUrl: ''
    }
  },
  computed: {
    agentId() {
      return parseInt(this.$route.params.id)
    }
  },
  created() {
    this.fetchData()
  },
  methods: {
    fetchData() {
      getCustomizeSite(
        { agent_id: this.agentId }
      ).then((res) => {
        if (res.code === 0) {
          this.isShowSiteLogo = res.data.is_show_site_logo ? '是' : '否'
          this.siteLogoUrl = res.data.site_logo_url
        }
      })
    }
  }
}
</script>
